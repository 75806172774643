import * as React from "react"
import { HeadFC } from "gatsby"
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl-v4"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import Nav from "../components/Nav"
import ProjectList from "../components/ProjectList"
import { GlobalHead } from "../components/GlobalHead"
import languageFromLocale from "../helper/language-helper"
import "../styles.scss"

const SacralBuildings = ({ data }) => {
  const intl = useIntl()
  const projects = data.allContentfulProject.nodes.filter(node => node.node_locale == languageFromLocale(intl.locale))
  return (
    <div>
      <Nav />
      <ProjectList title={intl.formatMessage({ id: "publicProjects" })} list={projects} />
    </div>
  )
}

export const query = graphql`
  query {
    allContentfulProject(
      filter: {metadata: {tags: {elemMatch: {name: {eq: "public"}}}}}
      sort: {fields: order, order: DESC}
    ) {
      nodes {
        createdAt
        contentful_id
        name
        node_locale
        description {
          description
          id
        }
        internalName
        attributes {
          name
          value
        }
        mainImage {
          url
        }
      }
    }
  }
`;

export default SacralBuildings

export function Head() {
  return (
    <GlobalHead title="Public buildings" />
  )
}
